exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-1-copy-js": () => import("./../../../src/pages/about-1 copy.js" /* webpackChunkName: "component---src-pages-about-1-copy-js" */),
  "component---src-pages-about-1-js": () => import("./../../../src/pages/about-1.js" /* webpackChunkName: "component---src-pages-about-1-js" */),
  "component---src-pages-about-2-js": () => import("./../../../src/pages/about-2.js" /* webpackChunkName: "component---src-pages-about-2-js" */),
  "component---src-pages-about-3-js": () => import("./../../../src/pages/about-3.js" /* webpackChunkName: "component---src-pages-about-3-js" */),
  "component---src-pages-academic-calendar-js": () => import("./../../../src/pages/academic-calendar.js" /* webpackChunkName: "component---src-pages-academic-calendar-js" */),
  "component---src-pages-author-js": () => import("./../../../src/pages/author.js" /* webpackChunkName: "component---src-pages-author-js" */),
  "component---src-pages-bigdata-analytics-js": () => import("./../../../src/pages/bigdata-analytics.js" /* webpackChunkName: "component---src-pages-bigdata-analytics-js" */),
  "component---src-pages-blog-1-js": () => import("./../../../src/pages/blog-1.js" /* webpackChunkName: "component---src-pages-blog-1-js" */),
  "component---src-pages-blog-2-js": () => import("./../../../src/pages/blog-2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-blog-3-js": () => import("./../../../src/pages/blog-3.js" /* webpackChunkName: "component---src-pages-blog-3-js" */),
  "component---src-pages-blog-4-js": () => import("./../../../src/pages/blog-4.js" /* webpackChunkName: "component---src-pages-blog-4-js" */),
  "component---src-pages-blog-5-js": () => import("./../../../src/pages/blog-5.js" /* webpackChunkName: "component---src-pages-blog-5-js" */),
  "component---src-pages-blog-6-js": () => import("./../../../src/pages/blog-6.js" /* webpackChunkName: "component---src-pages-blog-6-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-community-health-technology-department-about-1-js": () => import("./../../../src/pages/community-health-technology-department/about-1.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-about-1-js" */),
  "component---src-pages-community-health-technology-department-academic-program-js": () => import("./../../../src/pages/community-health-technology-department/academic-program.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-academic-program-js" */),
  "component---src-pages-community-health-technology-department-contact-js": () => import("./../../../src/pages/community-health-technology-department/contact.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-contact-js" */),
  "component---src-pages-community-health-technology-department-curriculum-js": () => import("./../../../src/pages/community-health-technology-department/curriculum.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-curriculum-js" */),
  "component---src-pages-community-health-technology-department-graduate-students-js": () => import("./../../../src/pages/community-health-technology-department/graduate-students.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-graduate-students-js" */),
  "component---src-pages-community-health-technology-department-head-of-department-js": () => import("./../../../src/pages/community-health-technology-department/head-of-department.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-head-of-department-js" */),
  "component---src-pages-community-health-technology-department-index-js": () => import("./../../../src/pages/community-health-technology-department/index.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-index-js" */),
  "component---src-pages-community-health-technology-department-laboratories-js": () => import("./../../../src/pages/community-health-technology-department/laboratories.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-laboratories-js" */),
  "component---src-pages-community-health-technology-department-projects-graduation-js": () => import("./../../../src/pages/community-health-technology-department/projects-graduation.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-projects-graduation-js" */),
  "component---src-pages-community-health-technology-department-schedules-js": () => import("./../../../src/pages/community-health-technology-department/schedules.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-schedules-js" */),
  "component---src-pages-community-health-technology-department-teaching-staff-js": () => import("./../../../src/pages/community-health-technology-department/teaching-staff.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-teaching-staff-js" */),
  "component---src-pages-community-health-technology-department-top-students-js": () => import("./../../../src/pages/community-health-technology-department/top-students.js" /* webpackChunkName: "component---src-pages-community-health-technology-department-top-students-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dean-of-the-college-js": () => import("./../../../src/pages/dean-of-the-college.js" /* webpackChunkName: "component---src-pages-dean-of-the-college-js" */),
  "component---src-pages-digital-agency-js": () => import("./../../../src/pages/digital-agency.js" /* webpackChunkName: "component---src-pages-digital-agency-js" */),
  "component---src-pages-digital-agency-portfolio-js": () => import("./../../../src/pages/digital-agency-portfolio.js" /* webpackChunkName: "component---src-pages-digital-agency-portfolio-js" */),
  "component---src-pages-facts-and-statistics-js": () => import("./../../../src/pages/facts-and-statistics.js" /* webpackChunkName: "component---src-pages-facts-and-statistics-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-feature-details-js": () => import("./../../../src/pages/feature-details.js" /* webpackChunkName: "component---src-pages-feature-details-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-graduate-students-js": () => import("./../../../src/pages/graduate-students.js" /* webpackChunkName: "component---src-pages-graduate-students-js" */),
  "component---src-pages-hosting-js": () => import("./../../../src/pages/hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructions-and-controls-js": () => import("./../../../src/pages/instructions-and-controls.js" /* webpackChunkName: "component---src-pages-instructions-and-controls-js" */),
  "component---src-pages-iot-js": () => import("./../../../src/pages/iot.js" /* webpackChunkName: "component---src-pages-iot-js" */),
  "component---src-pages-it-startup-2-js": () => import("./../../../src/pages/it-startup-2.js" /* webpackChunkName: "component---src-pages-it-startup-2-js" */),
  "component---src-pages-it-startup-js": () => import("./../../../src/pages/it-startup.js" /* webpackChunkName: "component---src-pages-it-startup-js" */),
  "component---src-pages-laboratories-js": () => import("./../../../src/pages/laboratories.js" /* webpackChunkName: "component---src-pages-laboratories-js" */),
  "component---src-pages-latest-news-js": () => import("./../../../src/pages/latestNews.js" /* webpackChunkName: "component---src-pages-latest-news-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-machine-learning-2-js": () => import("./../../../src/pages/machine-learning-2.js" /* webpackChunkName: "component---src-pages-machine-learning-2-js" */),
  "component---src-pages-machine-learning-js": () => import("./../../../src/pages/machine-learning.js" /* webpackChunkName: "component---src-pages-machine-learning-js" */),
  "component---src-pages-medical-laboratory-technology-department-about-1-js": () => import("./../../../src/pages/medical-laboratory-technology-department/about-1.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-about-1-js" */),
  "component---src-pages-medical-laboratory-technology-department-academic-program-js": () => import("./../../../src/pages/medical-laboratory-technology-department/academic-program.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-academic-program-js" */),
  "component---src-pages-medical-laboratory-technology-department-contact-js": () => import("./../../../src/pages/medical-laboratory-technology-department/contact.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-contact-js" */),
  "component---src-pages-medical-laboratory-technology-department-curriculum-js": () => import("./../../../src/pages/medical-laboratory-technology-department/curriculum.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-curriculum-js" */),
  "component---src-pages-medical-laboratory-technology-department-graduate-students-js": () => import("./../../../src/pages/medical-laboratory-technology-department/graduate-students.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-graduate-students-js" */),
  "component---src-pages-medical-laboratory-technology-department-head-of-department-js": () => import("./../../../src/pages/medical-laboratory-technology-department/head-of-department.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-head-of-department-js" */),
  "component---src-pages-medical-laboratory-technology-department-index-js": () => import("./../../../src/pages/medical-laboratory-technology-department/index.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-index-js" */),
  "component---src-pages-medical-laboratory-technology-department-laboratories-js": () => import("./../../../src/pages/medical-laboratory-technology-department/laboratories.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-laboratories-js" */),
  "component---src-pages-medical-laboratory-technology-department-projects-graduation-js": () => import("./../../../src/pages/medical-laboratory-technology-department/projects-graduation.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-projects-graduation-js" */),
  "component---src-pages-medical-laboratory-technology-department-schedules-js": () => import("./../../../src/pages/medical-laboratory-technology-department/schedules.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-schedules-js" */),
  "component---src-pages-medical-laboratory-technology-department-teaching-staff-js": () => import("./../../../src/pages/medical-laboratory-technology-department/teaching-staff.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-teaching-staff-js" */),
  "component---src-pages-medical-laboratory-technology-department-top-students-js": () => import("./../../../src/pages/medical-laboratory-technology-department/top-students.js" /* webpackChunkName: "component---src-pages-medical-laboratory-technology-department-top-students-js" */),
  "component---src-pages-pc-repair-js": () => import("./../../../src/pages/pc-repair.js" /* webpackChunkName: "component---src-pages-pc-repair-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-project-details-js": () => import("./../../../src/pages/project-details.js" /* webpackChunkName: "component---src-pages-project-details-js" */),
  "component---src-pages-projects-1-js": () => import("./../../../src/pages/projects-1.js" /* webpackChunkName: "component---src-pages-projects-1-js" */),
  "component---src-pages-projects-2-js": () => import("./../../../src/pages/projects-2.js" /* webpackChunkName: "component---src-pages-projects-2-js" */),
  "component---src-pages-projects-graduation-js": () => import("./../../../src/pages/projects-graduation.js" /* webpackChunkName: "component---src-pages-projects-graduation-js" */),
  "component---src-pages-radiology-technology-department-about-1-js": () => import("./../../../src/pages/radiology-technology-department/about-1.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-about-1-js" */),
  "component---src-pages-radiology-technology-department-academic-program-js": () => import("./../../../src/pages/radiology-technology-department/academic-program.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-academic-program-js" */),
  "component---src-pages-radiology-technology-department-contact-js": () => import("./../../../src/pages/radiology-technology-department/contact.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-contact-js" */),
  "component---src-pages-radiology-technology-department-curriculum-js": () => import("./../../../src/pages/radiology-technology-department/curriculum.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-curriculum-js" */),
  "component---src-pages-radiology-technology-department-graduate-students-js": () => import("./../../../src/pages/radiology-technology-department/graduate-students.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-graduate-students-js" */),
  "component---src-pages-radiology-technology-department-head-of-department-js": () => import("./../../../src/pages/radiology-technology-department/head-of-department.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-head-of-department-js" */),
  "component---src-pages-radiology-technology-department-index-js": () => import("./../../../src/pages/radiology-technology-department/index.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-index-js" */),
  "component---src-pages-radiology-technology-department-laboratories-js": () => import("./../../../src/pages/radiology-technology-department/laboratories.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-laboratories-js" */),
  "component---src-pages-radiology-technology-department-projects-graduation-js": () => import("./../../../src/pages/radiology-technology-department/projects-graduation.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-projects-graduation-js" */),
  "component---src-pages-radiology-technology-department-schedules-js": () => import("./../../../src/pages/radiology-technology-department/schedules.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-schedules-js" */),
  "component---src-pages-radiology-technology-department-teaching-staff-js": () => import("./../../../src/pages/radiology-technology-department/teaching-staff.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-teaching-staff-js" */),
  "component---src-pages-radiology-technology-department-top-students-js": () => import("./../../../src/pages/radiology-technology-department/top-students.js" /* webpackChunkName: "component---src-pages-radiology-technology-department-top-students-js" */),
  "component---src-pages-schedules-js": () => import("./../../../src/pages/schedules.js" /* webpackChunkName: "component---src-pages-schedules-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-1-js": () => import("./../../../src/pages/services-1.js" /* webpackChunkName: "component---src-pages-services-1-js" */),
  "component---src-pages-services-2-js": () => import("./../../../src/pages/services-2.js" /* webpackChunkName: "component---src-pages-services-2-js" */),
  "component---src-pages-services-3-js": () => import("./../../../src/pages/services-3.js" /* webpackChunkName: "component---src-pages-services-3-js" */),
  "component---src-pages-services-4-js": () => import("./../../../src/pages/services-4.js" /* webpackChunkName: "component---src-pages-services-4-js" */),
  "component---src-pages-services-5-js": () => import("./../../../src/pages/services-5.js" /* webpackChunkName: "component---src-pages-services-5-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-student-guide-js": () => import("./../../../src/pages/student-guide.js" /* webpackChunkName: "component---src-pages-student-guide-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-teaching-staff-js": () => import("./../../../src/pages/teaching-staff.js" /* webpackChunkName: "component---src-pages-teaching-staff-js" */),
  "component---src-pages-top-students-js": () => import("./../../../src/pages/top-students.js" /* webpackChunkName: "component---src-pages-top-students-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-categorie-template-js": () => import("./../../../src/templates/categorie-template.js" /* webpackChunkName: "component---src-templates-categorie-template-js" */),
  "component---src-templates-college-news-template-js": () => import("./../../../src/templates/college-news-template.js" /* webpackChunkName: "component---src-templates-college-news-template-js" */),
  "component---src-templates-feature-template-js": () => import("./../../../src/templates/feature-template.js" /* webpackChunkName: "component---src-templates-feature-template-js" */),
  "component---src-templates-first-department-site-blog-template-js": () => import("./../../../src/templates/FirstDepartmentSite-blog-template.js" /* webpackChunkName: "component---src-templates-first-department-site-blog-template-js" */),
  "component---src-templates-first-department-site-laboratories-template-js": () => import("./../../../src/templates/FirstDepartmentSite-laboratories-template.js" /* webpackChunkName: "component---src-templates-first-department-site-laboratories-template-js" */),
  "component---src-templates-first-department-site-news-template-js": () => import("./../../../src/templates/FirstDepartmentSite-news-template.js" /* webpackChunkName: "component---src-templates-first-department-site-news-template-js" */),
  "component---src-templates-laboratories-template-js": () => import("./../../../src/templates/laboratories-template.js" /* webpackChunkName: "component---src-templates-laboratories-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-second-department-site-blog-template-js": () => import("./../../../src/templates/SecondDepartmentSite-blog-template.js" /* webpackChunkName: "component---src-templates-second-department-site-blog-template-js" */),
  "component---src-templates-second-department-site-laboratories-template-js": () => import("./../../../src/templates/SecondDepartmentSite-laboratories-template.js" /* webpackChunkName: "component---src-templates-second-department-site-laboratories-template-js" */),
  "component---src-templates-second-department-site-news-template-js": () => import("./../../../src/templates/SecondDepartmentSite-news-template.js" /* webpackChunkName: "component---src-templates-second-department-site-news-template-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/service-template.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-third-department-site-blog-template-js": () => import("./../../../src/templates/ThirdDepartmentSite-blog-template.js" /* webpackChunkName: "component---src-templates-third-department-site-blog-template-js" */),
  "component---src-templates-third-department-site-laboratories-template-js": () => import("./../../../src/templates/ThirdDepartmentSite-laboratories-template.js" /* webpackChunkName: "component---src-templates-third-department-site-laboratories-template-js" */),
  "component---src-templates-third-department-site-news-template-js": () => import("./../../../src/templates/ThirdDepartmentSite-news-template.js" /* webpackChunkName: "component---src-templates-third-department-site-news-template-js" */)
}

